<script lang="ts">
	import TablerowContent from './TablerowContent.svelte';
	import { LoadAll, Job, ResourcesEntries, Detail, Translations } from '../types';
	import { removeResources } from '../utils/utilsStaffplan';

	export let loadAll: LoadAll;

	export let addToBackendData;
	export let backendData: any;
	export let jobs: Job[];
	export let check;
	export let translations: Translations;
	export let resourcesEntries: ResourcesEntries[];
	export let timeoffcategories;

	let tablerowContentProps = {
		addToBackendData,
		backendData,
		jobs,
		check,
		translations,
		timeoffcategories,
	};

	function handleRemove(event: CustomEvent<Detail>) {
		const { zugehoerigkeit, zugehoerigkeitID, kategorieID, freierMitarbeiter, subEmployeeId } = event.detail;

		const tableRowElement = document.querySelector(
			`tr[data-zugehoerigkeitid='${zugehoerigkeitID}'][data-zugehoerigkeit='${zugehoerigkeit}'][data-freiermitarbeiter='${freierMitarbeiter}']`
		);

		setTimeout(() => {
			let deleteButtonCollection = document.getElementsByClassName('personnelplan-delete');
			let deleteButton = deleteButtonCollection[0] as HTMLElement;

			deleteButton.onclick = e => {
				if (tableRowElement) {
					tableRowElement.remove();
					removeResources(
						tableRowElement as HTMLElement,
						zugehoerigkeit,
						zugehoerigkeitID,
						'anfragen',
						kategorieID,
						freierMitarbeiter,
						subEmployeeId
					);
				}
			};
		}, 200);
	}

	let hasVehicles = resourcesEntries.some(([key, resource]) => resource.Zugehoerigkeit === 'F');
</script>

{#if loadAll}
	{#each resourcesEntries as [key, resource] (key)}
		{#if resource.Zugehoerigkeit !== 'F'}
			<tr
				data-zugehoerigkeit={resource.Zugehoerigkeit}
				data-zugehoerigkeitID={resource.ZugehoerigkeitID}
				data-freierMitarbeiter={resource.FreierMitarbeiter}
				data-subEmployeeId={resource.SubEmployeeID}
			>
				<TablerowContent on:remove={handleRemove} {...tablerowContentProps} {resource} {key} hasCategory={false} />
			</tr>
		{/if}
	{/each}

	{#if hasVehicles}
		<tr class="headline nodrag">
			<td>{translations.vehicles}</td>
		</tr>
	{/if}

	{#each resourcesEntries as [key, resource] (key)}
		{#if resource.Zugehoerigkeit === 'F'}
			<tr
				data-zugehoerigkeit={resource.Zugehoerigkeit}
				data-zugehoerigkeitID={resource.ZugehoerigkeitID}
				data-freierMitarbeiter={resource.FreierMitarbeiter}
				data-subEmployeeId={resource.SubEmployeeID}
			>
				<TablerowContent on:remove={handleRemove} {...tablerowContentProps} {resource} {key} hasCategory={false} />
			</tr>
		{/if}
	{/each}
{:else}
	{#each resourcesEntries as [key, resource] (key)}
		{#if resource.Kategorien.filter(a => a.KategorieID === undefined).length > 0 && resource.Zugehoerigkeit !== 'F'}
			<tr
				data-zugehoerigkeit={resource.Zugehoerigkeit}
				data-zugehoerigkeitID={resource.ZugehoerigkeitID}
				data-freierMitarbeiter={resource.FreierMitarbeiter}
				data-subEmployeeId={resource.SubEmployeeID}
			>
				<TablerowContent on:remove={handleRemove} {...tablerowContentProps} {resource} {key} hasCategory={false} />
			</tr>
		{/if}
	{/each}
{/if}
