<script lang="ts">
	import { getDayOfWeek } from '../utils/utilsStaffplan';
	import { Job, SourceNode } from '../types';
	import { sourceNodeStore, isOnlyFutureJobsViewStore } from '../stores/stores';
	export let jobs: Job[];

	let lastJobPerDay = new Map();
	let currentDate = new Date();
	currentDate.setDate(currentDate.getDate() - 1);

	for (let i = 0; i < jobs.length; i++) {
		const currentJob = jobs[i];
		lastJobPerDay.set(currentJob.DatumVon, currentJob);
	}

	let isProject: boolean;
	let mainjobOrProjectId;

	sourceNodeStore.subscribe((sourceNode: SourceNode) => {
		mainjobOrProjectId = sourceNode.ID;
		isProject = sourceNode.Subtyp === 'P';
	})
</script>

{#each jobs as job, index}
	{#if !$isOnlyFutureJobsViewStore || (new Date(job.DatumBis.split(".").reverse().join("-")) >= currentDate)}
		{#if index === 0}
			<th class="transparent" style="min-width: 30px; max-width: 30px;" />
			<th
				class="center"
				style="height: calc(68px + 11ch); padding-bottom: 35px"
				data-terminid={job.ID}
				data-datum-von={job.DatumVon}
				data-datum-bis={job.DatumBis}
			>
				<div class="text-rotate--90" title={job.Titel}><div>{job.Titel}</div></div>
				<div class="dauer"
					>
					{getDayOfWeek(job.DatumVon)}, <br />
					{job.DatumVon} <br />
					{job.ZeitVon} <br /> - <br />
					{getDayOfWeek(job.DatumBis)}, <br />
					{job.DatumBis} <br />
					{job.ZeitBis}</div
				>
			</th>

			{#if !isProject}
				<th class="space" />
				<th class="space black" />
				<th class="space" />
			{/if}
		{:else}
			<th
				class="center"
				style="height: calc(68px + 11ch); padding-bottom: 35px"
				data-terminid={job.ID}
				data-datum-von={job.DatumVon}
				data-datum-bis={job.DatumBis}
			>
				<div class="text-rotate--90" title={job.Titel}><div>{job.Titel}</div></div>
				<div class="dauer"
					>
					{getDayOfWeek(job.DatumVon)}, <br />
					{job.DatumVon} <br />
					{job.ZeitVon} <br /> - <br />
					{getDayOfWeek(job.DatumBis)}, <br />
					{job.DatumBis} <br />
					{job.ZeitBis}</div
				>
			</th>

			{#if job === lastJobPerDay.get(job.DatumVon)}
				<th class="space black" />
			{/if}
		{/if}
	{/if}
{/each}
